<template>
  <div id="document_form_order">
    <b-col cols="12" :fields="fields" :items="documentList">
        <p></p>
      <div class="mb-5">
        <div class="mb-5">
          <el-table
            stripe
            :data="documentList"
            border
            style="width: 100%;"
            empty-text="Data tidak ditemukan"
          >
          <el-table-column label="Document Upload" align="center">
            <el-table-column align="center" type="index" fixed />
            <el-table-column prop="name" align="left" label="Nama Dokumen" >
            </el-table-column>
            <el-table-column prop="max_filesize" align="left" label="Ukuran" width="300"></el-table-column>
            <el-table-column prop="" align="center" label="Status" width="100" >
              <template #default="props">
              <span class="h3">
                <b-icon v-if="props.row.document_value" variant="success" icon="check-circle-fill"></b-icon>
                <b-icon v-else variant="danger" icon="x-circle-fill"></b-icon>
              </span>
            </template>
            </el-table-column>
            <el-table-column
              width="100"
              type="expand"
              label="Aksi"
            >
              <template #default="props">
                <div class="p-4 detail-document">
                  <div class="d-flex">
                    <div>
                      <div class="img">
                        <img
                          :style="{
                            'object-fit': 'contain',
                            display: props.row.document_value ? 'block' : 'none',
                          }"
                          :id="props.row.index + '-img'"
                          :src="
                            props.row.document_value
                              ? props.row.document_value
                              : ''
                          "
                          alt=""
                        />
                        <template>
                          <span
                            v-if="!props.row.document_value"
                            class="h1 card-image-wrapper"
                          >
                            <b-icon icon="card-image"></b-icon>
                          </span>
                          <span
                            @click="
                              () => {
                                documentChosee.id = props.row.id;
                                documentChosee.index = props.$index
                                documentChosee.name = props.row.name
                                  .split('-')
                                  .map(
                                    (word) =>
                                      word[0].toUpperCase() + word.slice('1')
                                  )
                                  .join(' ');
                                $bvModal.show('modal-unggah-dokumen');
                              }
                            "
                            class="btn-upload-document"
                            >Upload Dokumen</span
                          >
                          <input
                            style="display: none"
                            @change="setImgPreview($event, props.row.index)"
                            type="file"
                            :ref="props.row.index + '-file'"
                            accept="image/*"
                          />
                        </template>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="ml-5 d-flex flex-column align-items-start">
                        <span class="h3"
                          >Dokumen:
                          {{
                            props.row.name
                              .split("-")
                              .map(
                                (word) =>
                                  word[0].toUpperCase() + word.slice("1")
                              )
                              .join(" ")
                          }}</span
                        >
                        <template v-if="props.row.name == 'passpor'">
                          <span class="h3"
                            >Nomor Passpor:
                            {{ props.row.additionals[0].additional_value }}</span
                          >
                          <span class="h3"
                            >Tanggal Expired:
                            {{ props.row.additionals[1].additional_value }}</span
                          >
                        </template>
                        <span class="h3"
                          >Ukuran: {{ props.row.max_filesize + " KB" }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          </el-table>
        </div>
      </div>
    </b-col>

    <!-- modal -->
    <b-modal id="modal-unggah-dokumen" centered no-close-on-backdrop>
      <template #modal-header>
        <span class="fw-bold">{{ documentChosee.name }}</span>
      </template>
      <div class="upload-img d-flex flex-column ">
        <div class="">
          <div
            class="img-space"
            @drop.prevent="doDrop"
            @dragleave="doDragLeave"
            @dragover="doDragOver"
          >
            <!-- Crop Image -->
            <div>
              <cropper
                ref="cropper"
                :src="imageUpload"
                style=" 
                  position: absolute;
                  width: 100%; 
                  height: 100%;
                "
              />
              <div
                style="
                  position: absolute; 
                  z-index: 1;
                  left: 85%;
                  top: 80%;
                "
              >
                <b-btn deep-hidden @click="$refs['input-upload-file'].click()">
                  <b-icon icon="arrow-counterclockwise"></b-icon>
                </b-btn>
              </div>
            </div>

            <div id="show-before-preview">
              <span class="h1 card-image-wrapper">
                <b-icon icon="upload"></b-icon>
              </span>
              <span class="fw-bold">
                Drag & Drop file atau
                <span
                  class="text-primary pointer"
                  @click="$refs['input-upload-file'].click()"
                >
                  Cari
                </span>
              </span>
              <span class="text-center fs-12-px"
                >File gambar dengan jenis ekstensi file JPG, JPEG, dan PNG</span
              >
            </div>
          </div>
        </div>
        <input
          style="margin: 15px 35px; display: none;"
          type="file"
          @change="setImgPreview"
          accept="image/*"
          id="input-upload-file"
          ref="input-upload-file"
        />
        <div v-if="checkingAdditionals" class="mt-2 mx-4">
          <FormGroupInput
            v-for="(additional, index) in documentList[documentChosee.index].additionals"
            :key="index"
            placeholder=""
            width-background
            :type="checkTypeAdditionals(index)"
            v-model="additionals[index]"
            :label="additional.label"
            :need-label="true"
            rules="required"
          />
        </div>
      </div>
      <template #modal-footer>
        <div class="action">
          <b-button
            deep-hidden
            @click="
              () => {
                $bvModal.hide('modal-unggah-dokumen');
                imageUpload = null;
              }
            "
            class="mr-2"
            variant="secondary"
            >Batal</b-button
          >
          <b-button deep-hidden @click="postDocument" variant="primary">
            <font-awesome-icon
              icon="spinner"
              spin
              v-if="isLoading"
            ></font-awesome-icon>
            Unggah
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Order from "@/models/Order.js";
import FormGroupInput from "@/components/FormGroupInput";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

const OrderModel = new Order();

export default {
  components: {
    OrderModel,
    FormGroupInput,
    Cropper,
  },
  data: () => ({
    show: true,
    fields: ["Name", "Ukuran", "Status", "Detail"],
    documentList: [],
    document: [],
    documentChosee: { id: null, name: null, index: null },
    additionals: [],
    isLoading: false,
    imageUpload: null,
  }),
  computed: {
    checkingAdditionals: function() {
      if (
        this.documentChosee.index != null &&
        this.documentList[this.documentChosee.index].additionals.length > 0
      ) 
        return true
      return false
    },
  },
  methods: {
    checkTypeAdditionals: function(index) {
      if (
        this.documentList[this.documentChosee.index].additionals[index].type == 
        'string'
      ) {
        if (this.documentList[this.documentChosee.index].name == 'passpor') {
          if (index == 3) return 'text'
          return 'number'
        }
        else return 'text'
      }
      return this.documentList[this.documentChosee.index].additionals[index].type
    },
    postDocument: function() {
      this.isLoading = true;

      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob((blob) => {
        const formData = new FormData();
        formData.append("order_id", this.$route.params.id);
        formData.append("pilgrim_document_type_id", this.documentChosee.id);
        formData.append("document_file", blob);

        if (this.additionals.length > 0) {
          const additionals = this.documentList[this.documentChosee.index].additionals
          additionals.forEach(
            (additional, index) => {
              formData.append(`additionals[${index}][type_id]`, additional.id)
              formData.append(`additionals[${index}][value]`, this.additionals[index])
            }
          )
        }

        OrderModel.postDocument(formData)
          .then(() => {
            this.$store.dispatch(
              "notification/success",
              "Dokumen berhasil di unggah."
            );
            this.$bvModal.hide("modal-unggah-dokumen");
            this.getDocumentPilgrim();
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            this.$store.dispatch("notification/error", err);
          });
        this.imageUpload = null;
      }, "image/jpe");
    },
    setImgPreview: function(e) {
      const [file] = e.target.files;
      if (!file) return;
      if (this.imageUpload) URL.revokeObjectURL(this.imageUpload);
      const blob = URL.createObjectURL(file);
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.imageUpload = blob;
      };
      fileReader.readAsArrayBuffer(file);
    },
    doDrop: function(e) {
      const [file] = e.dataTransfer.files;
      console.log(file)
      if (file) {
        this.imageUpload = URL.createObjectURL(file);;
      }
    },
    doDragOver: function(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    doDragLeave: function(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    getDocumentPilgrim: function() {
      const csDocInput = [
        "scan-ktp",
        "phas-photo",
        "scan-ktp",
        "scan-bukti-chat-wa",
      ];
      this.documentList = [];
      OrderModel.getUploadDokumen(this.$route.params.id).then((resp) => {
        if (
          this.$store.getters["account/accountData"].role == "customer service"
        )
          this.documentList = resp.filter((data) =>
            csDocInput.includes(data.name)
          );
        else this.documentList = resp;
      });
    },
  },
  mounted: function() {
    this.getDocumentPilgrim();
  },
  destroyed: function() {
    if (this.imageUpload) URL.revokeObjectURL(this.imageUpload);
  },
};
</script>
