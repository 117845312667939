<template>
  <div id="detail-order">
    <div class="row d-flex justify-content-end mr-1 mb-3">
      <b-btn
        variant="warning"
        href="#/order"
        class="text-white"
        style="width: 7em;"
        >Kembali</b-btn
      >
      <b-btn
        variant="success"
        @click="doPrintPaymentInvoice"
        class="ml-2 text-white"
        style="width: 12em;"
        >Print Pembayaran</b-btn
      >
      <b-btn
        variant="primary"
        @click="doNextStep(0)"
        style="margin-left: 10px"
        class="text-white"
        v-if="whoIs() != 'customer service'"
      >
        {{ "Kembalikan Data" }}
      </b-btn>
      <b-btn
        variant="primary"
        @click="doNextStep(1)"
        style="margin-left: 10px"
        class="text-white"
      >
        {{ "Lempar Data" }}
      </b-btn>
    </div>
    <b-row>
      <b-col cols="12">
        <b-row class="mb-5">
          <b-col cols="12">
            <b-card header-tag="header">
              <b-row class="mb-3">
                <b-col cols="7">
                  <h5 class="mb-0">{{ $route.name }}</h5>
                </b-col>
                <b-col cols="5" class="d-flex justify-content-end">
                  <b-btn
                    v-if="whoIs('admin')"
                    variant="success"
                    class="mr-2"
                    @click="doSendVa"
                    >Kirim Virtual Account</b-btn
                  >
                  <b-btn
                    v-if="whoIs('admin')"
                    variant="success"
                    @click="doSendPilgrmPdf"
                    >Kirim Bukti Pendaftaran</b-btn
                  >
                  <b-btn
                    v-if="whoIs('back office')"
                    variant="success"
                    @click="doPrintDocShispent"
                  >
                    Print Surat Perlengkapan
                  </b-btn>
                </b-col>
              </b-row>
              <b-row> </b-row>
              <b-row>
                <b-col cols="3">
                  <label class="form-label w-100" style="font-size: 80%;"
                    >Nama</label
                  >
                  <p class="heading-color">{{ pilgrim.name }}</p>
                </b-col>
                <b-col cols="3">
                  <label class="form-label w-100" style="font-size: 80%;"
                    >NIK</label
                  >
                  <p class="heading-color">{{ pilgrim.nik }}</p>
                </b-col>
                <b-col cols="3">
                  <label class="form-label w-100" style="font-size: 80%;"
                    >Nomor HP</label
                  >
                  <p class="heading-color">{{ pilgrim.phone_number }}</p>
                </b-col>
                <b-col cols="3">
                  <label class="form-label w-100" style="font-size: 80%;"
                    >Nomor Whatsapp</label
                  >
                  <p class="heading-color">{{ pilgrim.wa_number }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3">
                  <label class="form-label w-100" style="font-size: 80%;"
                    >Tanggal Lahir</label
                  >
                  <p class="heading-color">{{ pilgrim.dob }}</p>
                </b-col>
                <b-col cols="3">
                  <label class="form-label w-100" style="font-size: 80%;"
                    >Pekerjaan</label
                  >
                  <p class="heading-color">{{ pilgrim.job }}</p>
                </b-col>
                <b-col cols="3">
                  <label class="form-label w-100" style="font-size: 80%;"
                    >Jenis Kelamin</label
                  >
                  <p>{{ pilgrim.gender }}</p>
                </b-col>
                <b-col cols="3">
                  <label class="form-label w-100" style="font-size: 80%;"
                    >Status Perkawinan</label
                  >
                  <p class="heading-color">{{ pilgrim.marriage_status }}</p>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <template v-for="(m, index) in model">
                  <b-col
                    :key="index"
                    cols="3"
                    v-if="
                      (typeof m.disabled === 'undefined' || !m.disabled) &&
                        m.type !== 'hidden'
                    "
                  >
                    <detail-group-input
                      :key="index"
                      :type="m.type"
                      :label="typeof m.label === 'string' ? m.label : null"
                      :value="m.value"
                      :id="index"
                    >
                      <template
                        slot="content_status"
                        slot-scope="defaultSlotScope"
                      >
                        <div class="">
                          <span
                            :class="[
                              'badge',
                              defaultSlotScope.colData == 'unpaid'
                                ? 'badge-danger'
                                : 'badge-success',
                            ]"
                          >
                            {{ defaultSlotScope.colData }}
                          </span>
                        </div>
                      </template>

                      <template
                        slot="content_buyer_note"
                        slot-scope="defaultSlotScope"
                      >
                        {{ defaultSlotScope.colData.buyer_note }}
                      </template>
                      <template
                        slot="content_sub_total"
                        slot-scope="defaultSlotScope"
                      >
                        <span class="heading-color">{{
                          formatCurrency(defaultSlotScope.colData)
                        }}</span>
                      </template>
                      <template
                        slot="content_total"
                        slot-scope="defaultSlotScope"
                      >
                        <span class="heading-color">{{
                          formatCurrency(defaultSlotScope.colData)
                        }}</span>
                      </template>
                      <template
                        slot="content_is_active"
                        slot-scope="defaultSlotScope"
                      >
                        <span class="heading-color">{{
                          defaultSlotScope.colData == 1
                            ? "Aktif"
                            : "Tidak Aktif"
                        }}</span>
                      </template>
                      <template
                        slot="content_registrant"
                        slot-scope="defaultSlotScope"
                      >
                        {{ defaultSlotScope.colData.name }}
                      </template>
                      <template>
                        <label class="form-label w-100" style="font-size: 80%;"
                          >Status Perkawinan</label
                        >
                        <p class="heading-color">
                          {{ pilgrim.marriage_status }}
                        </p>
                      </template>
                    </detail-group-input>
                  </b-col>
                </template>
              </b-row>
              <h5 class="mt-5">Info Data Jama'ah</h5>
              <b-row>
                <!-- <b-col cols="2">
                  <b-img rounded="circle" alt="Circle image" :src="pilgrim.self_portrait_url" width="150" height="150" class="border" />
                </b-col> -->
                <b-col cols="3">
                  <label class="form-label w-100" style="font-size: 80%;"
                    >Nama</label
                  >
                  <p class="heading-color">{{ pilgrim.name }}</p>
                </b-col>

                <b-col cols="3">
                  <label class="form-label w-100" style="font-size: 80%;"
                    >NIK</label
                  >
                  <p class="heading-color">{{ pilgrim.nik }}</p>
                </b-col>

                <b-col cols="3">
                  <label class="form-label w-100" style="font-size: 80%;"
                    >Nomor HP</label
                  >
                  <p class="heading-color">{{ pilgrim.phone_number }}</p>
                </b-col>

                <b-col cols="3">
                  <label class="form-label w-100" style="font-size: 80%;"
                    >Nomor Whatsapp</label
                  >
                  <p class="heading-color">{{ pilgrim.wa_number }}</p>
                </b-col>
                <b-col cols="3">
                  <label class="form-label w-100" style="font-size: 80%;"
                    >Bulan Keberangkatan</label
                  >
                  <p class="heading-color">
                    {{
                      pilgrim.month_depature
                        ? $moment(pilgrim.month_depature).format("MMMM-YYYY")
                        : "-"
                    }}
                  </p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12">
        <div class="mb-5 deep-detail">
          <b-card no-body class="card-custom">
            <b-tabs card pills>
              <b-tab title="History Payment" active>
                <div class="w-100 d-flex justify-content-end my-3">
                  <b-button
                    v-if="whoIs('back office') || whoIs('admin')"
                    @click="printMutasi"
                    variant="primary mr-2"
                    >Print Mutasi</b-button
                  >
                  <b-button v-b-modal.addPaymentStore variant="primary"
                    >Tambah Pembayaran</b-button
                  >
                </div>
                <div class="mb-5">
                  <el-table
                    stripe
                    :data="mutasi"
                    border
                    style="width: 100%;"
                    empty-text="Data tidak ditemukan"
                  >
                    <el-table-column type="index" fixed />
                    <el-table-column prop="date" align="center" label="Date" />
                    <el-table-column
                      prop="payment_method"
                      width="200"
                      label="Metode Pembayaran"
                      align="center"
                    />
                    <el-table-column label="Mutasi" align="center">
                      <el-table-column
                        align="right"
                        prop="kredit"
                        label="Kredit"
                      />
                      <el-table-column
                        align="right"
                        prop="debit"
                        label="Debit"
                      />
                    </el-table-column>
                    <el-table-column
                      prop="saldo"
                      label="Saldo"
                      align="right"
                    ></el-table-column>
                    <el-table-column
                      width="100"
                      type="expand"
                      props="action"
                      label="Aksi"
                    >
                      <template #default="props">
                        <div class="d-flex justify-content-end p-3">
                          <b-button  @click="doSendKwitansiPdf(props.row.id)" variant="primary">Kirim Kwitansi</b-button>
                        </div>
                        <div
                          class="d-flex flex-column justify-content-center w-100 align-items-center m-3"
                        >
                          <h4>Bukti Pembayaran</h4>
                          <img
                            width="300"
                            v-if="props.row.image"
                            :src="props.row.image"
                            alt=""
                          />
                          <span v-else>Tidak Ada Bukti Pembayaran</span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </b-tab>
              <b-tab title="Document">
                <document-form />
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </b-col>

      <b-modal id="addPaymentStore" centered title="Pembayaran">
        <div
          class="d-flex flex-column justify-contnet-center align-items-center"
        >
          <form-group-input-vue
            placeholder=" "
            width-background
            ref="id_card"
            class="w-100"
            type="number"
            rules="required"
            label="Jumlah Bayar"
            :need-label="true"
            v-model="paymentPrice"
            id="payment-price"
          />
          <form-group-input-vue
            placeholder=" "
            width-background
            ref="metode-transaksi"
            class="w-100"
            type="enum"
            :options="[
              { value: 'Cash', text: 'Tunai' },
              { value: 'Transfer', text: 'Transfer' },
            ]"
            rules="required"
            label="Metode Transaksi"
            :need-label="true"
            v-model="paymentMethods"
            id="bukti-transaksi"
          />

          <div>
            <label style="font-weight: 500"
              >Bukti Pembayaran
              <span style="font-weight: 300">(optional)</span></label
            >
            <b-form-file
              v-model="paymentPhoto"
              placeholder="Bukti Pembayaran"
              drop-placeholder="Drop file here..."
            ></b-form-file>
          </div>

          <b-button
            variant="primary"
            class="w-100 mt-3"
            @click="addPaymentStore"
            >Kirim Pembayaran</b-button
          >
        </div>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import { setModelValue, formatModelValue } from "@/_helpers";
import detailGroupInput from "@/components/DetailGroupInput.vue";
import Order from "@/models/Order.js";
import HistoryModel from "@/models/PaymentHistory.js";
import FormGroupInputVue from "@/components/FormGroupInput";
import DocumentForm from "./components/document_form.vue";
const OrderModel = new Order();
const paymentHistoryModel = new HistoryModel();
export default {
  components: {
    DocumentForm,
    detailGroupInput,
    FormGroupInputVue,
  },
  watch: {
    "$route.params.id": function(val) {
      if (val !== undefined) {
        this.getDetail();
      }
    },
  },
  data() {
    return {
      id: null,
      isLoading: false,
      apiUrl: {
        paymentHistory:
          paymentHistoryModel.endpoint + "?order_id=" + this.$route.params.id,
      },
      documentsForms: [],
      model: OrderModel.init(),
      pilgrim: null,
      payment: null,
      package: null,
      dokumen: null,
      mutasi: [],
      paymentHistory: {
        column: [
          {
            prop: "payment_method",
            label: "Metode",
            sortable: true,
            resizable: true,
            minWidth: 200,
          },
          {
            prop: "type",
            label: "Tipe",
            sortable: true,
            resizable: true,
            minWidth: 200,
          },
          {
            prop: "amount",
            label: "Harga",
            sortable: true,
            resizable: true,
            minWidth: 200,
            needCurrency: true,
          },
          {
            prop: "status",
            label: "Status",
            sortable: true,
            resizable: true,
            minWidth: 200,
          },
        ],
        items: [],
        dataParams: ["order_id"],
      },
      /* Payment Variabel */
      paymentPhoto: "",
      paymentNote: "Dillakukan oleh customer service",
      paymentPrice: null,
      paymentMethods: null,
      paymentStatus: "approved",
      paymentType: "debit",
    };
  },
  created() {
    this.getDetail();
    this.getDetailPaymentHistory();
    this.getDocumentModel();
  },
  computed: {
    buttonTextStep: function() {
      if (this.whoIs() == "customer service") return "Back Office";
      else if (this.whoIs() == "back office") return "Manifest";
      else return "";
    },
  },
  methods: {
    doSendVa: function() {
      OrderModel.resendVirtualAccount(this.$route.params.id )
    },
    doPrintPaymentInvoice: function() {
      OrderModel.printPaymentInvoice(this.model.order_no.value);
    },
    doPrintDocShispent: function() {
      OrderModel.printDocShispent(this.model.order_no.value);
    },
    printMutasi: function() {
      OrderModel.printMutsi(this.$route.params.id);
    },
    addPaymentStore: function() {
      let paymentPost = new FormData();
      paymentPost.append("order_id", this.$route.params.id);
      paymentPost.append("package_id", this.package);
      paymentPost.append("amount", this.paymentPrice);
      paymentPost.append("payment_method", this.paymentMethods);
      paymentPost.append("type", this.paymentType);
      paymentPost.append("attachment", this.paymentPhoto);
      paymentPost.append("note", this.paymentNote);
      paymentPost.append("status", this.paymentStatus);

      OrderModel.addPaymentHistory(paymentPost)
        .then((resp) => {
          this.$store.dispatch(
            "notification/success",
            "Dokumen berhasil di unggah."
          );
          this.$bvModal.hide("addPaymentStore");
          this.getMutasi(this.$route.params.id);
          this.getDetail();
        })
        .catch((err) => {
          this.$store.dispatch("notification/error", err);
        });
    },
    formatCurrency(value) {
      return formatModelValue("price", value);
    },
    getDetail() {
      this.isLoading = true;
      OrderModel.find(this.$route.params.id)
        .then((resp) => {
          this.isLoading = false;
          setModelValue(this.model, resp);

          this.model.package = {
            value: resp.details[0].orderdetailable.name,
            label: "Paket Pilihan",
            type: "text",
          };
          this.model.totalPrice = {
            value: this.formatCurrency(resp.total),
            label: "Total Pembayaran",
            type: "text",
          };

          // console.log(resp) pilgrim
          this.model.pilgrim = {
            label: "Bulan Keberangkatan",
            value: resp.pilgrims[0] && resp.pilgrims[0].month_depature ? this.$moment(resp.pilgrim.month_depature).format(
              "MMMM YYYY"
            ) : '',
          };
          // console.log(this.model)

          this.model.package = {
            value: resp.details[0].orderdetailable.name,
            label: "Paket Pilihan",
            type: "text",
          };
          this.model.totalPrice = {
            value: this.formatCurrency(resp.total),
            label: "Total Pembayaran",
            type: "text",
          };
          this.package = resp.details[0].orderdetailable_id;
          if (this.$store.getters["account/accountData"].role !== "admin") {
            delete this.model["sub_total"];
            delete this.model["total"];
            delete this.model["total_paid"];
          }
          this.pilgrim = { ...resp.pilgrims[0], gender: resp.user.gender };
          this.paymentHistory.items = resp.details;
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            console.error(error);
          }
          this.isLoading = false;
        });
    },
    doNextStep: function(value) {
      let title;
      let currentRole = this.whoIs();

      if (value == 1) {
        if (currentRole == "customer service") title = "ke Back Office";
        else if (currentRole == "back office") title = "ke Manifest";
        else title = "";

        title = "Anda  yakin melanjutkan data ini " + title;
      } else if (value == 0) {
        if (currentRole == "back office") title = "ke Customer Service";
        else if (currentRole == "manifest") title = "ke Back Office";
        else title = "";

        title = "Anda yakin mengembalikan data ini " + title;
      }

      this.$swal
        .fire({
          title,
          text: "Pastikan semua data telah benar",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Simpan",
        })
        .then((result) => {
          if (result.isConfirmed) {
            OrderModel.assignToNextStep(value, this.$route.params.id)
              .then((resp) => {
                this.$store.dispatch(
                  "notification/success",
                  "Order Berhasil diperbaharui."
                );
                this.$router.push("/order");
              })
              .catch((err) => {
                this.$store.dispatch("notification/error", err);
                this.$router.push("/order");
              });
          }
          return;
        });
    },
    whoIs: function(role = null) {
      if (role) return this.$store.getters["account/accountData"].role == role;
      return this.$store.getters["account/accountData"].role;
    },
    getDetailPaymentHistory() {
      this.isLoading = true;
      paymentHistoryModel
        .list({ order_id: this.$route.params.id })
        .then((resp) => {
          this.$forceUpdate();
          this.payment = resp.data;
          this.isLoading = false;
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            console.error(error);
          }
          this.isLoading = false;
        });
    },
    getDocumentModel() {
      this.isLoading = true;
      documentModel
        .list({ order_id: this.$route.params.id })
        .then((resp) => {
          this.isLoading = false;
          this.documentsForms = [];
          resp.data.forEach((data) => {
            this.documentsForms[data.id] = {
              main_document: data.document_value,
              additionals: [],
            };
            data.additionals.forEach((additional) => {
              this.documentsForms[data.id].additionals[additional.id] = {
                value: additional.additional_value,
              };
            });
          });
          this.dokumen = resp.data;
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            console.error(error);
          }
          console.log(error);
          this.isLoading = false;
        });
    },
    doSendPilgrmPdf: function() {
      OrderModel.resendPilgrimPdfSms(this.pilgrim.id).then(
        this.$store.dispatch(
          "notification/success",
          "Sms Bukti Pendaftaran Berhasil Dikirim Ulang."
        )
      ).catch(() => {
        this.$store.dispatch("notification/error", "Gagal Mengirim Sms");
      })
    },
    doSendKwitansiPdf: function(paymentHistoryId) {
      OrderModel.resendKwitansiSms(this.pilgrim.id, paymentHistoryId).then(
        this.$store.dispatch(
          "notification/success",
          "Sms Kwitansi Berhasil Dikirim Ulang."
        )
      ).catch(() => {
        this.$store.dispatch("notification/error", "Gagal Mengirim Sms");
      })
    },
    submitOrderDocument(idDocument, namaDocument) {
      let selectedDocument = this.dokumen.filter(function(document) {
        return document.id == idDocument;
      });
      // validasi
      if (this.documentsForms[idDocument].main_document === null) {
        alert(namaDocument + " tidak boleh kosong!");
      } else if (
        this.documentsForms[idDocument].main_document.type !== "image/jpg" &&
        this.documentsForms[idDocument].main_document.type !== "image/png" &&
        this.documentsForms[idDocument].main_document.type !== "image/jpeg"
      ) {
        alert("Extensi file salah!");
      } else {
        Object.keys(this.documentsForms[idDocument].additionals).forEach(
          (idAdditional) => {
            if (
              this.documentsForms[idDocument].additionals[idAdditional]
                .value === null
            ) {
              let selectedAdditional = selectedDocument[0].additionals.filter(
                function(document) {
                  return document.id == idAdditional;
                }
              );
              alert(selectedAdditional[0].label + " tidak boleh kosong!");
              return false;
            }
          }
        );
        let formData = new FormData();
        formData.append("order_id", this.$route.params.id);
        formData.append("pilgrim_document_type_id", idDocument);
        formData.append(
          "document_file",
          this.documentsForms[idDocument].main_document
        );
        Object.keys(this.documentsForms[idDocument].additionals).forEach(
          (idAdditional, index) => {
            formData.append(
              "additionals[" + index + "][type_id]",
              idAdditional
            );
            formData.append(
              "additionals[" + index + "][value]",
              this.documentsForms[idDocument].additionals[idAdditional].value
            );
          }
        );
        this.$http
          .post(documentModel.endpoint, formData)
          .then(() => {
            this.isLoading = true;
            this.getDocumentModel();
          })
          .catch((error) => {
            this.isLoading = false;
            this.$store.dispatch("notification/error", error);
          });
      }
      // console.log(this.documentsForms[idDocument])
    },
    deleteOrderDocument(documentId) {
      documentModel
        .delete(documentId)
        .then(() => {
          this.isLoading = true;
          this.getDocumentModel();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
        });
    },
    checkpilgrim(key) {
      const show = [
        "phone_number",
        "wa_number",
        "nik",
        "dob",
        "marriage_status",
        "job",
        "address",
        "name",
        "passport_number",
        "social_media",
        "id_card_url",
        "self_portrait_url",
        "province",
        "district",
        "regency",
        "village",
      ];
      if (show.includes(key)) return true;
      return false;
    },
    changeSwitch(value) {
      this.$swal
        .fire({
          title: "Anda yakin?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.value) {
            let data = {};
            data["is_active"] = value;
            this.$http
              .patch(OrderModel.endpoint + "/" + this.$route.params.id, data)
              .then(() => {
                this.getDetail();
                this.$store.dispatch(
                  "notification/success",
                  "Order Berhasil diperbaharui."
                );
              })
              .catch((error) => {
                if (process.env.NODE_ENV !== "production") {
                  console.error(error);
                }
                this.$store.dispatch("notification/error", error);
                this.isLoading = false;
              });
          }
        });
    },
    getMutasi: async function(id) {
      const mutasi = await OrderModel.getPaymentHistory(id);
      const bindMutasi = [];
      let calSaldo = 0;
      mutasi.forEach((data) => {
        calSaldo =
          data.type == "debit"
            ? calSaldo + data.amount
            : calSaldo - data.amount;
        bindMutasi.push({
          id: data.id,
          date: this.$moment(data.created_at).format("l"),
          payment_method: data.payment_method,
          kredit: data.type == "debit" ? "-" : this.formatCurrency(data.amount),
          debit: data.type == "debit" ? this.formatCurrency(data.amount) : "-",
          saldo: this.formatCurrency(calSaldo),
          image: data.attachment,
          action: "<",
        });
      });
      this.mutasi = bindMutasi;
    },
  },
  mounted: function() {
    console.log('tet')
    this.getMutasi(this.$route.params.id);
  },
};
</script>
